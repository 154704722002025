/* eslint-disable import/prefer-default-export */
import { refreshLinkClickTracking } from "@snowplow/browser-plugin-link-click-tracking";
import {
  trackPageView as trackPageViewViaSnowplow,
  trackSelfDescribingEvent,
} from "@snowplow/browser-tracker";
import { funnelKey } from "./constants";
import { listingEntity } from "./entities";
import {
  getFunnelId,
  removeFunnelId,
  trackCreateListingStep3Adapter,
  trackFiltersAdapter,
  trackListingsSearchResultClickAdapter,
  trackRequestToBookStep2Adapter,
  trackRequestToBookStep3Adapter,
} from "./utils";

// This function tries to delay execution of events until "COOKIE_CONSENT_STATUS_KNOWN"
// is true. Also, if after 5 tries and we still don't know the value of "COOKIE_CONSENT_STATUS_KNOWN"
// then we run the event and also log a warning on rollbar
// A case where this might occur might be if the user is on an extremely slow network
// Or a case where for some reason the cookie event listener which sets this value fails to run
// PS: All events must be wrapped with this function
const delayEventExecution = (callback, runCount = 1) => {
  if (typeof callback !== "function") {
    throw new Error("Callback must be a function");
  }

  if (window.COOKIE_CONSENT_STATUS_KNOWN) {
    return callback();
  }

  if (!window.COOKIE_CONSENT_STATUS_KNOWN && runCount === 5) {
    return callback();
  }

  return setTimeout(() => delayEventExecution(callback, runCount + 1), 500);
};

export const trackPageView = ({ pageName }) => {
  // Snowplow only tracks clicks on links which exist when the page has loaded.
  // Hence, during client side routing new links are added to the document and are not tracked by snowplow
  // To fix this, we need to call [refreshLinkClickTracking] anytime a page is viewed so we can track the links for that page.
  refreshLinkClickTracking();
  delayEventExecution(() => trackPageViewViaSnowplow({ title: pageName }));
};

/* ======= Request to book ======= */

export const trackRequestToBookStep1 = ({
  formData: { from, optionToExtend, to },
  proceedToNextStep,
  listing,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/event_request_to_book_01/jsonschema/1-0-0",
        data: {
          date_from: from,
          date_to: to,
          option_to_extend: optionToExtend,
          funnel_id: getFunnelId(funnelKey.REQUEST_TO_BOOK),
          proceed_to_next_step: proceedToNextStep,
        },
      },
      context: [listingEntity(listing)],
    });
  });
};

export const trackRequestToBookStep2 = ({
  formData,
  formErrors,
  proceedToNextStep,
  listing,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/event_request_to_book_02/jsonschema/2-0-1",
        data: trackRequestToBookStep2Adapter({
          formData,
          proceedToNextStep,
          formErrors,
        }),
      },
      context: [listingEntity(listing)],
    });
  });
};

export const trackRequestToBookStep3 = ({
  formData,
  formErrors,
  proceedToNextStep,
  listing,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/event_request_to_book_03/jsonschema/2-0-0",
        data: trackRequestToBookStep3Adapter({
          formData,
          proceedToNextStep,
          formErrors,
        }),
      },
      context: [listingEntity(listing)],
    });
  });
};

export const trackRequestToBookStep4 = ({ proceedToNextStep, listing }) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/event_request_to_book_04/jsonschema/1-0-0",
        data: {
          funnel_id: getFunnelId(funnelKey.REQUEST_TO_BOOK),
          proceed_to_next_step: proceedToNextStep,
        },
      },
      context: [listingEntity(listing)],
    });
  });
};

export const trackRequestToBookResponse = ({ requestSuccessful, listing }) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/event_request_to_book_05/jsonschema/1-0-0",
        data: {
          funnel_id: getFunnelId(funnelKey.REQUEST_TO_BOOK),
          request_successful: requestSuccessful,
        },
      },
      context: [listingEntity(listing)],
    });

    if (requestSuccessful) {
      removeFunnelId(funnelKey.REQUEST_TO_BOOK);
    }
  });
};

export const trackAboutTheBookingStepOnBookingWizardCompleted = ({
  tenantUserId,
  fromDate,
  toDate,
  requestOptionToExtend,
  listingRequestType,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/about_the_booking_step_on_booking_wizard_completed/jsonschema/1-0-0",
        data: {
          tenant_user_id: tenantUserId,
          from_date: fromDate,
          to_date: toDate,
          request_option_to_extend: requestOptionToExtend,
          listing_request_type: listingRequestType,
        },
      },
    });
  });
};

export const trackAboutTheTenantsStepOnBookingWizardCompleted = ({
  tenantUserId,
  numberOfPets,
  numberOfAdults,
  childrenAge,
  listingRequestType,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/about_the_tenants_step_on_booking_wizard_completed/jsonschema/1-0-0",
        data: {
          tenant_user_id: tenantUserId,
          number_of_pets: numberOfPets,
          number_of_adults: numberOfAdults,
          children_age: childrenAge,
          listing_request_type: listingRequestType,
        },
      },
    });
  });
};

export const trackAboutRelocationStepOnBookingWizardCompleted = ({
  tenantUserId,
  reasonForStay,
  whoIsPaying,
  listingRequestType,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/about_relocation_step_on_booking_wizard_completed/jsonschema/1-0-0",
        data: {
          tenant_user_id: tenantUserId,
          request_reason_for_stay: reasonForStay,
          who_is_paying: whoIsPaying,
          listing_request_type: listingRequestType,
        },
      },
    });
  });
};

export const trackListingRequestCreated = ({
  tenantUserId,
  listingCity,
  listingRequestId,
  bookingRequestId,
  listingId,
  groupId,
  totalRent,
  monthlyRent,
  fromDate,
  toDate,
  reasonForStay,
  requestOptionToExtend,
  totalStayDays,
  createdBySales,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:com.wunderflats/listing_request_created/jsonschema/1-0-0",
        data: {
          tenant_user_id: tenantUserId,
          listing_city: listingCity,
          listing_request_id: listingRequestId,
          booking_request_id: bookingRequestId,
          listing_id: listingId,
          group_id: groupId,
          request_total_rent: totalRent,
          request_monthly_rent: monthlyRent,
          from_date: fromDate,
          to_date: toDate,
          request_reason_for_stay: reasonForStay,
          request_option_to_extend: requestOptionToExtend,
          request_total_stay_days: totalStayDays,
          created_by_sales: createdBySales,
        },
      },
    });
  });
};

export const trackLogoOnBookingWizardClicked = ({
  userId,
  groupId,
  bookingWizardStep,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/logo_on_booking_wizard_clicked/jsonschema/1-0-0",
        data: {
          user_id: userId,
          group_id: groupId,
          booking_wizard_step: bookingWizardStep,
        },
      },
    });
  });
};

/* ======= Listing search result click ======= */

export const trackListingsSearchResultClick = ({
  pageNumber,
  clickPlacement,
  pageSize,
  itemIndexOnPage,
  total,
  listing,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/event_search_result_click/jsonschema/1-0-0",
        data: trackListingsSearchResultClickAdapter({
          pageNumber,
          clickPlacement,
          pageSize,
          itemIndexOnPage,
          total,
        }),
      },
      context: [listingEntity(listing)],
    });
  });
};

/* ======= Create Listing  ======= */

export const trackCreateListingStep1 = ({
  formData,
  formErrors: { addressNotFound = false, inCompleteAddress = false },
  proceedToNextStep,
  interactedWithClearAddressButton,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:com.wunderflats/event_create_listing_01/jsonschema/1-0-0",
        data: {
          country: formData.address.countryName || null,
          city: formData.address.city || null,
          street: formData.address.street || null,
          zipcode: formData.address.zipCode || null,
          button_clear_address: interactedWithClearAddressButton,
          proceed_to_next_step: proceedToNextStep,
          funnel_id: getFunnelId(funnelKey.CREATE_LISTING),
          error_address_not_found: addressNotFound,
          error_complete_address: inCompleteAddress,
        },
      },
    });
  });
};

export const trackCreateListingStep2 = ({
  formData,
  proceedToNextStep,
  formErrors: { belowMinimumRent = false },
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:com.wunderflats/event_create_listing_02/jsonschema/1-0-1",
        data: {
          rent: Math.round(Number(formData.rent)) ?? null,
          proceed_to_next_step: proceedToNextStep,
          funnel_id: getFunnelId(funnelKey.CREATE_LISTING),
          error_min_rent: belowMinimumRent,
        },
      },
    });
  });
};

export const trackCreateListingStep3 = ({
  formData,
  proceedToNextStep,
  formErrors,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:com.wunderflats/event_create_listing_03/jsonschema/1-0-0",
        data: trackCreateListingStep3Adapter({
          formData,
          proceedToNextStep,
          formErrors,
        }),
      },
    });
  });
};

export const trackCreateListingResponse = ({ requestSuccessful }) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:com.wunderflats/event_create_listing_04/jsonschema/1-0-0",
        data: {
          funnel_id: getFunnelId(funnelKey.CREATE_LISTING),
          request_successful: requestSuccessful,
        },
      },
    });

    if (requestSuccessful) {
      removeFunnelId(funnelKey.CREATE_LISTING);
    }
  });
};

export const trackSignUpButton = ({
  status,
  phoneNumberRequiredExperimentGroup,
  validPhoneNumber,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:com.wunderflats/event_signup/jsonschema/1-0-1",
        data: {
          status,
          /* eslint-disable camelcase */
          experiment_group_variant: phoneNumberRequiredExperimentGroup,
          valid_phone_number: validPhoneNumber || false,
          /* eslint-enable camelcase */
        },
      },
    });
  });
};

export const trackCookieFirstChange = ({ consent }) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:com.wunderflats/privacy_status_set/jsonschema/1-0-0",
        data: {
          consent_to_necessary: consent.necessary || true,
          consent_to_performance: consent.performance || false,
          consent_to_functional: consent.functional || false,
          consent_to_advertising: consent.advertising || false,
        },
      },
    });
  });
};

export const trackAccountCompletionAttempt = ({
  schema,
  tenantUserId,
  bookingRequestId,
  buttonLocation,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema,
        data: {
          tenant_user_id: tenantUserId,
          booking_request_id: bookingRequestId,
          button_location: buttonLocation,
        },
      },
    });
  });
};

export const trackRequestConfirmationAttempt = ({
  bookingRequestId,
  tenantUserId,
  listingRequestId,
  isProfileComplete,
  areRequiredDocumentsUploaded,
  requestGroup,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/request_confirm_attempted/jsonschema/1-0-0",
        data: {
          booking_request_id: bookingRequestId,
          tenant_user_id: tenantUserId,
          listing_request_id: listingRequestId,
          tenant_profile_completed: isProfileComplete,
          tenant_documents_uploaded: areRequiredDocumentsUploaded,
          ...requestGroup,
        },
      },
    });
  });
};

/* New Snowplow events with updated schemas */
/**
 * The schema details on AVO:
 * https://www.avo.app/schemas/zIOzKMFCXTI9tedW6eMB/events/e/WxoqyKfd-z
 */

export const trackSearchOnHomepage = ({
  userId,
  location,
  fromDate,
  toDate,
} = {}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/search_on_homepage_applied/jsonschema/1-0-0",
        data: {
          user_id: userId,
          location,
          from_date: fromDate,
          to_date: toDate,
        },
      },
    });
  });
};

export const trackListingOnLdpRequestToBookButtonClicked = ({
  tenantUserId,
  groupId,
  fromDate,
  toDate,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/listing_on_ldp_request_to_book_button_clicked/jsonschema/1-0-0",
        data: {
          tenant_user_id: tenantUserId,
          group_id: groupId,
          from_date: fromDate,
          to_date: toDate,
        },
      },
    });
  });
};

export const trackCollectionClickedOnHomepage = ({
  userId,
  location,
  collectionId,
} = {}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/collection_on_homepage_clicked/jsonschema/1-0-0",
        data: {
          user_id: userId,
          location,
          collection_id: collectionId,
        },
      },
    });
  });
};

export const trackSearchFilterOnSrp = ({ userId, location, filters } = {}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/search_filter_on_srp_applied/jsonschema/1-0-0",
        data: {
          user_id: userId,
          location,
          ...trackFiltersAdapter(filters),
        },
      },
    });
  });
};

export const trackLocationChangeOnSrp = ({ userId, location } = {}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:com.wunderflats/location_on_srp_changed/jsonschema/1-0-0",
        data: {
          user_id: userId,
          location,
        },
      },
    });
  });
};

export const trackMapBboxChange = ({ userId, location, bbox } = {}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/bounding_box_on_srp_map_changed/jsonschema/1-0-0",
        data: {
          user_id: userId,
          location,
          bbox,
        },
      },
    });
  });
};

export const trackSearchResultsDisplayed = ({ userId, listingIds } = {}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/listings_on_srp_displayed/jsonschema/1-0-0",
        data: {
          user_id: userId,
          group_ids: listingIds,
        },
      },
    });
  });
};

export const trackSearchResultsPageChange = ({
  userId,
  searchResultsPage,
} = {}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:com.wunderflats/page_on_srp_changed/jsonschema/1-0-0",
        data: {
          user_id: userId,
          page_number: searchResultsPage,
        },
      },
    });
  });
};

export const trackListingOnSearchResultsPageClicked = ({
  userId,
  groupId,
  position,
  pageNumber,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema: "iglu:com.wunderflats/listing_on_srp_clicked/jsonschema/1-0-0",
        data: {
          user_id: userId,
          group_id: groupId,
          position,
          page_number: pageNumber,
        },
      },
    });
  });
};

export const trackListingOnMapClicked = ({ userId, groupId }) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/listing_on_srp_map_clicked/jsonschema/1-0-0",
        data: {
          user_id: userId,
          group_id: groupId,
        },
      },
    });
  });
};

export const findMoreApartmentsButtonClicked = ({ userId, groupId }) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/find_more_apartments_button_on_booking_wizard_clicked/jsonschema/1-0-0",
        data: {
          user_id: userId,
          group_id: groupId,
        },
      },
    });
  });
};

export const trackSimilarListingClickedOnLDP = ({
  userId,
  clickedGroupId,
  currentGroupId,
  position,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/listing_on_similar_listings_on_ldp_clicked/jsonschema/1-0-0",
        data: {
          user_id: userId,
          clicked_group_id: clickedGroupId,
          current_group_id: currentGroupId,
          position,
        },
      },
    });
  });
};

export const trackSimilarListingClickedOnBookingWizard = ({
  userId,
  clickedGroupId,
  currentGroupId,
  position,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/listing_on_similar_listings_on_booking_wizard_clicked/jsonschema/1-0-0",
        data: {
          user_id: userId,
          clicked_group_id: clickedGroupId,
          current_group_id: currentGroupId,
          position,
        },
      },
    });
  });
};

export const trackWishlistListingClicked = ({ userId, groupId, position }) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/listing_on_wishlist_clicked/jsonschema/1-0-0",
        data: {
          user_id: userId,
          group_id: groupId,
          position,
        },
      },
    });
  });
};

export const trackMissingPhoneNumberNextClicked = () => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:com.wunderflats/missing_phone_number_next_clicked/jsonschema/1-0-0",
      data: {},
    },
  });
};

export const trackSignupClicked = ({ loginMethod, isNewUser = false }) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:com.wunderflats/signup_clicked/jsonschema/2-0-0",
      data: {
        login_method: loginMethod,
        is_new_user: isNewUser,
      },
    },
  });
};

export const trackLandlordIntentClicked = ({ triggerLocation }) => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:com.wunderflats/ll_intent_clicked/jsonschema/1-0-0",
      data: {
        trigger_location: triggerLocation,
      },
    },
  });
};

export const trackAddressPageNextClicked = () => {
  trackSelfDescribingEvent({
    event: {
      schema: "iglu:com.wunderflats/address_page_next_clicked/jsonschema/1-0-0",
      data: {},
    },
  });
};

/**
 * Tracks the event when the contract signing review step is viewed.
 *
 * @param {Object} params - The parameters for tracking the event.
 * @param {string} params.bookingOfferId - The ID of the booking offer.
 * @param {string} params.landlordId - The ID of the landlord user.
 */
export const trackContractSigningReviewStepViewed = ({
  bookingOfferId,
  landlordId,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/contract_signing_review_step_viewed/jsonschema/1-0-0",
        data: {
          booking_offer_id: bookingOfferId,
          landlord_user_id: landlordId,
        },
      },
    });
  });
};

/**
 * Tracks the event when the contract review deposit bank account is changed.
 *
 * @param {Object} params - The parameters for tracking the event.
 * @param {string} params.bookingOfferId - The ID of the booking offer.
 * @param {string} [params.landlordId] - The ID of the landlord user.
 */

export const trackContractReviewDepositBankAccountChanged = ({
  bookingOfferId,
  landlordId,
}) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:com.wunderflats/contract_review_deposit_bank_account_changed/jsonschema/1-0-0",
      data: {
        booking_offer_id: bookingOfferId,
        user_id: landlordId,
      },
    },
  });
};

/**
 * Tracks the event when the contract review rent bank account is changed.
 *
 * @param {Object} params - The parameters for tracking the event.
 * @param {string} params.bookingOfferId - The ID of the booking offer.
 * @param {string} [params.landlordId] - The ID of the landlord user.
 */

export const trackContractReviewRentBankAccountChanged = ({
  bookingOfferId,
  landlordId,
}) => {
  trackSelfDescribingEvent({
    event: {
      schema:
        "iglu:com.wunderflats/contract_review_rent_bank_account_changed/jsonschema/1-0-0",
      data: {
        booking_offer_id: bookingOfferId,
        user_id: landlordId,
      },
    },
  });
};

/**
 * Tracks the event when the contract review rent payment method is changed.
 *
 * @param {Object} params - The parameters for tracking the event.
 * @param {string} params.bookingOfferId - The ID of the booking offer.
 * @param {string} [params.landlordId] - The ID of the landlord user.
 */

export const trackContractSigningAcceptStepViewed = ({
  bookingOfferId,
  landlordId,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/contract_signing_accept_step_viewed/jsonschema/1-0-0",
        data: {
          booking_offer_id: bookingOfferId,
          user_id: landlordId,
        },
      },
    });
  });
};

/**
 * Tracks the event when the contract signing success step is viewed.
 *
 * @param {Object} params - The parameters for tracking the event.
 * @param {string} params.bookingOfferId - The ID of the booking offer.
 * @param {string} [params.landlordId] - The ID of the landlord user.
 */

export const trackContractSigningSuccessStepViewed = ({
  bookingOfferId,
  landlordId,
}) => {
  delayEventExecution(() => {
    trackSelfDescribingEvent({
      event: {
        schema:
          "iglu:com.wunderflats/contract_signing_success_step_viewed/jsonschema/1-0-0",
        data: {
          booking_offer_id: bookingOfferId,
          landlord_user_id: landlordId,
        },
      },
    });
  });
};
